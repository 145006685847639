<template>
  <div class="ordered-upload">
    <FileUpload
        name="demo[]"
        :multiple="multiple"
        :customUpload="true"
        @uploader="myUploader"
        :showCancelButton="false"
        :showUploadButton="true"
    >
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>

    <OrderList
        v-if="multiple && allowOrdering"
        :modelValue="modelValue"
        @update:modelValue="updateOrder"
        listStyle="height:auto"
        dataKey="url"
        class="mt-4"
        :key="orderListKey"
    >
      <template #header> Media Ordering Zone</template>
      <template #item="slotProps">
        <div class="p-caritem flex justify-between items-center" :data-url="slotProps.item.url">
          <div class="flex items-center">
            <span class="p-caritem-vin">{{ slotProps.index + 1 }}</span>
            <div class="w-64">
              <media-loader
                  :src="slotProps.item.url"
                  aspectRatio="100%"
                  controls
                  class="overflow-hidden media-item media-item-height"
              />
            </div>
          </div>
          <div class="ml-6 ">
            {{ slotProps.item.is_preview ? "This video marked as a preview video!" : "" }}
            <span class="cursor-pointer underline text-blue m-2"
                  :class="{'text-red-700': slotProps.item.is_preview}"
                  @click="togglePreview(slotProps.item)">
              [{{ !slotProps.item.is_preview ? "Mark as Preview" : "Remove Preview" }}]
            </span>
            <div class="text-xs">(Mark this video as preview when the main video isn't square. Video will not be shown on the drop page)</div>
          </div>
          <div class="ml-6 text-red-700" @click="removeMedia(slotProps.item)">
            Remove
          </div>
        </div>
      </template>
    </OrderList>
  </div>
</template>

<script>
import {MediaService} from "@/services/apiService";
import {ref} from "vue";
import FileUpload from "primevue/fileupload";
import OrderList from "primevue/orderlist";
import MediaLoader from "@/components/Media/MediaLoader.vue";
import {useToast} from "primevue/usetoast";

export default {
  name: "OrderedUpload",
  components: {FileUpload, OrderList, MediaLoader},
  props: {
    modelValue: {
      type: Array,
      default: new Array(),
    },
    allowOrdering: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const modelValue = ref(props.modelValue);
    const orderListKey = ref(Date.now());
    const toast = useToast();

    const myUploader = (event) => {
      const formData = new FormData();
      for (const i of Object.keys(event.files)) {
        formData.append('files', event.files[i])
      }
      return MediaService.upload(formData).then((res) => {
        if (res.status) {
          const images = res.data;
          if (props.multiple) {
            modelValue.value.push(...images);
          } else {
            modelValue.value = images;
          }
          ctx.emit("update:modelValue", modelValue.value);
        }
      }).catch(() => {
        toast.add({severity: 'error', summary: 'Error', detail: 'Media not uploaded', life: 3000});
      })
    };

    const updateOrder = (event) => {
      modelValue.value = event;
      ctx.emit("update:modelValue", modelValue.value);
    };

    const removeMedia = (event) => {
      MediaService.delete(event.id).then(res => {
        if (res.status) {
          toast.add({severity: 'info', summary: 'Success', detail: 'Media removed', life: 3000});
          modelValue.value = modelValue.value.filter(
              (item) => item.url !== event.url
          );
          ctx.emit("update:modelValue", modelValue.value);
          orderListKey.value = Date.now(); // Force re-render as the component is buggy
        } else {
          toast.add({severity: 'error', summary: 'Error', detail: 'Media not deleted', life: 3000});
        }
      }).catch(() => {
        toast.add({severity: 'error', summary: 'Error', detail: 'Media not deleted', life: 3000});
      })

    };

    const togglePreview = (media) => {
      MediaService.preview(media.id).then(res => {
        if (res.status) {
          toast.add({severity: 'info', summary: 'Success', detail: 'Media updated, reloading...', life: 3000});
          window.location.reload();
          ctx.emit("update:modelValue", modelValue.value);
          orderListKey.value = Date.now(); // Force re-render as the component is buggy
        } else {
          toast.add({severity: 'error', summary: 'Error', detail: 'ERROR', life: 3000});
        }
      }).catch(() => {
        toast.add({severity: 'error', summary: 'Error', detail: 'ERROR', life: 3000});
      })
    }
    return {
      myUploader,
      updateOrder,
      removeMedia,
      togglePreview,
      orderListKey,
    };
  },
};
</script>

<style lang="scss">
.p-caritem-vin {
  @apply mr-4 w-8 h-8 flex items-center justify-center rounded-full text-white;
  background: #2196F3;
  border: 1px solid #2196F3;
}
</style>
